import React from 'react'
import styled from 'styled-components'

import { PlanPricingPage } from '@eig-builder/module-plan-pricing-page'

import { createPricingCardData } from './pricing.utility'
import { FEATURE_COMPARISION_ALL, PLAN_TYPES } from './pricing-data'
import { getCurrencySymbol } from 'helpers/currency-helper'

import './style/pricing.scss'
import './lang'

const Container = styled.section`
  max-width: 1312px;
  margin: 0 auto;
  text-align: center;
`
const planTypes = PLAN_TYPES.map(label => ({ label }))

const Pricing = () => (
  <section className='pricing p-3'>
    <Container>
      <PlanPricingPage pricingCards={createPricingCardData()} pricingGrid={{ planTypes, featureComparisonData: FEATURE_COMPARISION_ALL }} />
      <div className='row footnote' style={{ marginTop: 16 }}>
        <div className='col-md-8 text-center'>** Qualified 1 year or 2 year plans include a free domain registration for one year. If you wish to cancel, there is a non-refundable {getCurrencySymbol().domainPrice} domain fee.</div>
        <div className='col-md-8 text-center'>*** Drive high quality traffic to your website with targeted search ads. Spend $25 and get $100 Microsoft Ad Credits, plus a spending match of up to $150 with Google Ads. Valid for US/CA only</div>
      </div>
    </Container>
  </section>
)

export default Pricing
