import { goTo } from '../../helpers/go-to-helper'
import { getCurrencySymbol } from 'helpers/currency-helper'

const PRICE_DEFINITION = {
  discount: 0.6,
  premium:{
    1: 22.95,
    12: 21.95,
    24: 18.95,
    36: 11.95,
    getPrices (term = 12){ 
      return PRICE_DEFINITION.price(this, term)
  }
  },
  business:{
    1: 29.95,
    12: 24.95,
    24: 19.95,
    36: 11.95,
    getPrices (term = 12){ 
      return PRICE_DEFINITION.price(this, term)
  }
  },
  ecommerce:{
    1: 42.95,
    12: 32.95,
    24: 27.95,
    36: 11.95,
    getPrices (term = 12){ 
      return PRICE_DEFINITION.price(this, term)
  }
},
  price (plan, term = 12) {
    return{
      totalDiscountedPrice: ((plan[term] * (1- this.discount)) * term).toFixed(2),
      totalPrice: plan[term] * term,
      monthlyDiscountedPrice : (plan[term] * (1- this.discount)).toFixed(2),
      monthlyPrice: plan[term] 
    }
  }
}
const navigateToVariantsURL = path => {
  const url = `${ process.env.GATSBY_CHECKOUT_SITE_URL_SINGLE_PAGE }?smallplans=1&${ path }`
 
  goTo(url)
}

const currencySymbol = getCurrencySymbol().symbol

export const createPricingCardData = () => [
  {
    title: 'Starter',
    // subtitle: 'Create & Publish for FREE',
    pricing: {
      primary: {
        amount: 'FREE',
      },
    },
    buttonText: 'Start for Free',
    featured: false,
    showShadow: true,
    showFeatures: false,
    showViewFeatures: false,
    showStrikethrough: true,
    showAnnualEncouragement: true,
    showUpgradeButton: true,
    showVat: false,
    buttonFeatured: false,
    startOnboardingOnClick: () => {
      goTo(process.env.GATSBY_SIGNUP_SITE_URL)
    },
  },
  {
    title: 'Premium',
    // subtitle: 'Grow your Business',
    pricing: {
      primary: {
        currency: currencySymbol,
        amount: PRICE_DEFINITION.premium.getPrices(12).monthlyDiscountedPrice,
        frequency: 'mo*',
      },
      secondary: {
        currency: currencySymbol,
        amount: PRICE_DEFINITION.premium.getPrices(12).monthlyPrice,
        frequency: 'mo',
      },
    },
    annualEncouragement: `billed yearly at ${ currencySymbol }${PRICE_DEFINITION.premium.getPrices(12).totalDiscountedPrice}`,
    buttonText: 'Get Started',
    featured: false,
    showShadow: true,
    showFeatures: false,
    showViewFeatures: false,
    showStrikethrough: true,
    showAnnualEncouragement: true,
    showVat: false,
    buttonFeatured: true,
    bannerText: 'Upgrade to Me',
    startOnboardingOnClick: () => {
      navigateToVariantsURL(
        `title=Premium%20Plan&term=12&price=13200&sku=PLAN2&landerType=pricing&couponCode=DISCOUNT60`
      )
    },
  },
  {
    bannerText: '',
    title: 'Business',
    // subtitle: '',
    pricing: {
      primary: {
        currency: currencySymbol,
        amount: PRICE_DEFINITION.business.getPrices(12).monthlyDiscountedPrice,
        frequency: 'mo*',
      },
      secondary: {
        currency: currencySymbol,
        amount: PRICE_DEFINITION.business.getPrices(12).monthlyPrice,
        frequency: 'mo',
      },
    },
    annualEncouragement: `billed yearly at ${ currencySymbol }${PRICE_DEFINITION.business.getPrices(12).totalDiscountedPrice}`,
    buttonText: 'Get Started',
    featured: false,
    showShadow: true,
    showFeatures: false,
    showViewFeatures: false,
    showStrikethrough: true,
    showAnnualEncouragement: true,
    showVat: false,
    buttonFeatured: false,
    startOnboardingOnClick: () => {
      navigateToVariantsURL(
        `title=Business%20Plan&term=12&price=16800&sku=PLAN3&landerType=pricing&couponCode=DISCOUNT60`
      )
    },
  },
  {
    bannerText: '',
    title: 'Ecommerce',
    // subtitle: 'Best for Online Stores',
    pricing: {
      primary: {
        currency: currencySymbol,
        amount: PRICE_DEFINITION.ecommerce.getPrices(12).monthlyDiscountedPrice,
        frequency: 'mo*',
      },
      secondary: {
        currency: currencySymbol,
        amount: PRICE_DEFINITION.ecommerce.getPrices(12).monthlyPrice,
        frequency: 'mo',
      },
    },
    annualEncouragement: `billed yearly at ${ currencySymbol }${PRICE_DEFINITION.ecommerce.getPrices(12).totalDiscountedPrice}`,
    buttonText: 'Get Started',
    featured: false,
    showShadow: true,
    showFeatures: false,
    showViewFeatures: false,
    showStrikethrough: true,
    showAnnualEncouragement: true,
    showVat: false,
    buttonFeatured: false,
    startOnboardingOnClick: () => {
      navigateToVariantsURL(
        `title=eCommerce%20Plan&term=12&price=24000&sku=PLAN4&landerType=pricing&couponCode=DISCOUNT60`
      )
    },
  },
]
