import { Localizations } from '@eig-builder/module-localization'

Localizations.append({
  modules: {
    account: {
      planUpgrade: {
        twoHundredAdCredits: 'Up to $250 Ad Credits',
        addCredit: 'ad credit offers***',
        addCreditFootNote: '*Only available in the US/CA.',
        advanced: 'advanced',
        advancedIntegrated: 'advanced (integrated)',
        analytics: 'analytics',
        automatedEmailScheduling: 'automated email scheduling',
        basic: 'basic',
        blog: 'blog',
        booking: 'bookings',
        business: 'business',
        businessPlus: 'business plus',
        campaignSendRefills: 'campaign send refills',
        campaignsPerMonth: 'campaigns per month',
        chatEmail: '24/7 chat and email',
        connectYourDomain: 'connect your domain',
        contactManagement: 'contact management',
        contactsUploadLimitPerFile: 'contacts upload limit per file',
        contactsUploadOrManually: 'contacts upload or manually added limit per day',
        couponsDiscounts: 'coupons and discounting',
        digitalProducts: 'digital products/downloads',
        discountAndCoupons: 'discount & coupons',
        displayAds: 'display ads',
        ecommerce: 'ecommerce',
        emailMarketing: 'email marketing',
        facebook: 'facebook integration',
        fastWebsites: 'fast websites (CDN)',
        free: 'free',
        freeDomain: 'free domain for 1 yr**',
        freeDomainFootNote: '*Included only when purchasing 1 or 2 yr plan.',
        freeHosting: 'FREE hosting',
        freeUnsplashImageLibrary: 'free unsplash image library',
        integratedContactsCRM: 'integrated contacts CRM',
        integratedEcommerceLandingPages: 'integrated ecommerce landing pages',
        intelligentSiteBuilder: 'intelligent site builder',
        leadCapture: 'lead capture forms',
        logoBuilder: 'logo builder',
        marketing: 'marketing',
        marketingTools: 'marketing tools',
        matchYourLogo: 'match your logo & website branding',
        maximumProducts: 'maximum products',
        maximumNumberOfProducts: 'maximum number of products',
        menusAndEventSection: 'menus & event sections',
        mobileFriendlyLayouts: 'mobile-friendly layouts',
        na: 'NA',
        naIndividualOnly: 'NA - Individual Only',
        no: 'no',
        none: 'none',
        onlinePayments: 'online payments',
        onlineStore: 'online store',
        opt: 'Opt.',
        orderInventoryTax: 'order, inventory, and tax management',
        orderAndTaxManagement: 'order & tax management',
        phoneSupport: 'phone support',
        premium: 'premium',
        prioritySupport: 'priority support',
        professionalAIDrivenDesigns: 'professional AI driven designs',
        removeWebsiteBannerAd: 'remove website banner ad',
        secureWebsite: 'secure website (SSL)',
        security: 'SSL (Security)',
        securityModal: 'Automatically included in all plans, SSL encrypts your website traffic to keep your customers\' information safe, such as credit card number, account passwords, and contact info.',
        shareBlogPostsToEmail: 'share blog posts to email',
        shippingManagement: 'shipping management',
        simplifiedOptOutManagement: 'simplified Opt-Out management',
        siteStatistics: 'site statistics',
        socialShare: 'social share (site & products)',
        starter: 'starter',
        starterFree: 'starter (free)',
        storage: 'storage',
        storageModal: 'Enjoy unlimited storage for all your images and other files you upload to your website.',
        store: 'store',
        support: 'support',
        totalContacts: 'total contacts',
        transactionalEmails: 'transactional emails',
        transactionFee: 'transaction fee',
        uniqueEmailsPerMonth: 'unique emails per month',
        unlimited: 'unlimited',
        unlimitedStorage: 'unlimited storage',
        unlimitedStorageOfImages: 'unlimited storage of images',
        unsplashImageLibrary: 'unsplash image library',
        visualAnalyticsAndCampaignReporting: 'visual analytics & campaign reporting',
        websiteEssentials: 'website essentials',
        websitePopupLeadCaptureForms: 'website Pop-Up lead capture forms'
      }
    }
  }
})
