/* All Plan Types */
export const PLAN_TYPES = ['modules.account.planUpgrade.starterFree', 'modules.account.planUpgrade.premium', 'modules.account.planUpgrade.business', 'modules.account.planUpgrade.ecommerce']

/* WEBSITE ESSENTIALS SECTION */
const WEBSITE_ESSENTIALS_HEADER = ['modules.account.planUpgrade.websiteEssentials', ...PLAN_TYPES]

const INTELLIGENT_SITE_BUILDER = ['modules.account.planUpgrade.intelligentSiteBuilder', true, true, true, true]
const FREE_HOSTING = ['modules.account.planUpgrade.freeHosting', true, true, true, true]
const UNLIMITED_STORAGE = ['modules.account.planUpgrade.unlimitedStorage', true, true, true, true]
const REMOVE_WEBSITE_BANNER_AD = ['modules.account.planUpgrade.removeWebsiteBannerAd', 'modules.account.planUpgrade.no', false, false, false]
const FREE_DOMAIN = ['modules.account.planUpgrade.freeDomain', 'modules.account.planUpgrade.no', true, true, true]
const CONNECT_YOUR_DOMAIN = ['modules.account.planUpgrade.connectYourDomain', 'modules.account.planUpgrade.no', true, true, true]
const BOOKING = ['modules.account.planUpgrade.booking', 'modules.account.planUpgrade.no', true, true, true]
const FAST_WEBSITES = ['modules.account.planUpgrade.fastWebsites', true, true, true, true]
const SECURE_WEBSITES = ['modules.account.planUpgrade.secureWebsite', true, true, true, true]
const UNSPLASH_IMAGE_LIBRARY = ['modules.account.planUpgrade.unsplashImageLibrary', true, true, true, true]

const WEBSITE_ESSENTIALS_ROW = [INTELLIGENT_SITE_BUILDER, FREE_HOSTING, UNLIMITED_STORAGE, REMOVE_WEBSITE_BANNER_AD, FREE_DOMAIN, CONNECT_YOUR_DOMAIN, BOOKING, FAST_WEBSITES, SECURE_WEBSITES, UNSPLASH_IMAGE_LIBRARY]

const WEBSITE_ESSENTIALS = {
  header: WEBSITE_ESSENTIALS_HEADER,
  rows: WEBSITE_ESSENTIALS_ROW,
  modals: [],
  id: 'WEBSITE_ESSENTIALS'
}

/* EMAIL MARKETING */
const EMAIL_MARKETING_HEADER = ['modules.account.planUpgrade.emailMarketing', ...PLAN_TYPES]

const TOTAL_CONTACTS = ['modules.account.planUpgrade.totalContacts', null, null, null, null].fill('modules.account.planUpgrade.unlimited', 1, 5)
const CAMPAIGNS_PER_MONTH = ['modules.account.planUpgrade.campaignsPerMonth', '2', '3', '5', '10']
const UNIQUE_EMAILS_PER_MONTH = ['modules.account.planUpgrade.uniqueEmailsPerMonth', '100', '250', '500', '3,000']
const CAMPAIGN_SEND_REFILLS = ['modules.account.planUpgrade.campaignSendRefills', 'modules.account.planUpgrade.na', null, null, null].fill('modules.account.planUpgrade.opt', 2, 5)
const CONTACTS_UPLOAD_LIMIT_PER_FILE = ['modules.account.planUpgrade.contactsUploadLimitPerFile', 'modules.account.planUpgrade.naIndividualOnly', '250', '500', '1,250']
const CONTACTS_UPLOAD_OR_MANUALLY = ['modules.account.planUpgrade.contactsUploadOrManually', 'modules.account.planUpgrade.naIndividualOnly', '250', '500', '1,250']
const INTEGRATED_CONTACTS_CRM = ['modules.account.planUpgrade.integratedContactsCRM', true, true, true, true]
const PROFESSIONAL_AI_DRIVEN_DESIGN = ['modules.account.planUpgrade.professionalAIDrivenDesigns', true, true, true, true]
const UNLIMITED_STORAGE_OF_IMAGES = ['modules.account.planUpgrade.unlimitedStorageOfImages', true, true, true, true]
const FREE_UNSPLASH_IMAGE_LIBRARY = ['modules.account.planUpgrade.freeUnsplashImageLibrary', true, true, true, true]
const MATCH_YOUR_LOGO_AND_WEBSITE_BRANDING = ['modules.account.planUpgrade.matchYourLogo', true, true, true, true]
const MOBILE_FRIENDLY_LAYOUTS = ['modules.account.planUpgrade.mobileFriendlyLayouts', true, true, true, true]
const INTEGRATED_ECOMMERCE_LANDING_PAGES = ['modules.account.planUpgrade.integratedEcommerceLandingPages', true, true, true, true]
const WEBSITE_POP_UP_LEAD_CAPTURE_FORMS = ['modules.account.planUpgrade.websitePopupLeadCaptureForms', true, true, true, true]
const VISUAL_ANALYTICS_AND_CAMPAIGN_REPORTING = ['modules.account.planUpgrade.visualAnalyticsAndCampaignReporting', true, true, true, true]
const SHARE_BLOG_POSTS_TO_EMAIL = ['modules.account.planUpgrade.shareBlogPostsToEmail', true, true, true, true]
const SIMPLIFIED_OPT_OUT_MANAGEMENT = ['modules.account.planUpgrade.simplifiedOptOutManagement', true, true, true, true]
const AUTOMATED_EMAIL_SCHEDULING = ['modules.account.planUpgrade.automatedEmailScheduling', 'NA', true, true, true]

const EMAIL_MARKETING_ROW = [TOTAL_CONTACTS, CAMPAIGNS_PER_MONTH, UNIQUE_EMAILS_PER_MONTH, CAMPAIGN_SEND_REFILLS,
  CONTACTS_UPLOAD_LIMIT_PER_FILE, CONTACTS_UPLOAD_OR_MANUALLY, INTEGRATED_CONTACTS_CRM, PROFESSIONAL_AI_DRIVEN_DESIGN, UNLIMITED_STORAGE_OF_IMAGES, FREE_UNSPLASH_IMAGE_LIBRARY, MATCH_YOUR_LOGO_AND_WEBSITE_BRANDING, MOBILE_FRIENDLY_LAYOUTS,
  INTEGRATED_ECOMMERCE_LANDING_PAGES, WEBSITE_POP_UP_LEAD_CAPTURE_FORMS, VISUAL_ANALYTICS_AND_CAMPAIGN_REPORTING, SHARE_BLOG_POSTS_TO_EMAIL, SIMPLIFIED_OPT_OUT_MANAGEMENT, AUTOMATED_EMAIL_SCHEDULING]

const EMAIL_MARKETING = {
  header: EMAIL_MARKETING_HEADER,
  rows: EMAIL_MARKETING_ROW,
  modals: [],
  id: 'EMAIL_MARKETING'
}

/* MARKETING TOOLS */
const MARKETING_TOOLS_HEADER = ['modules.account.planUpgrade.marketingTools', ...PLAN_TYPES]

const CONTACT_MANAGEMENT = ['modules.account.planUpgrade.contactManagement', true, true, true, true]
const LEAD_CAPTURE = ['modules.account.planUpgrade.leadCapture', true, true, true, true]
const MENU_AND_EVENT_SECTION = ['modules.account.planUpgrade.menusAndEventSection', true, true, true, true]
const LOGO_MAKER = ['modules.account.planUpgrade.logoBuilder', true, true, true, true]
const SITE_STATISTICS = ['modules.account.planUpgrade.siteStatistics', 'modules.account.planUpgrade.basic', 'modules.account.planUpgrade.advanced', 'modules.account.planUpgrade.advanced', 'modules.account.planUpgrade.advanced']
const AD_CREDIT = ['modules.account.planUpgrade.addCredit', 'modules.account.planUpgrade.none', 'modules.account.planUpgrade.twoHundredAdCredits', 'modules.account.planUpgrade.twoHundredAdCredits', 'modules.account.planUpgrade.twoHundredAdCredits']

const MARKETING_TOOLS_ROW = [CONTACT_MANAGEMENT, LEAD_CAPTURE, MENU_AND_EVENT_SECTION, LOGO_MAKER, SITE_STATISTICS, AD_CREDIT]

const MARKETING_TOOLS = {
  header: MARKETING_TOOLS_HEADER,
  rows: MARKETING_TOOLS_ROW,
  modals: [],
  id: 'MARKETING_TOOLS'
}

/* ONLINE STORE */
const ONLINE_STORE_HEADER = ['modules.account.planUpgrade.onlineStore', ...PLAN_TYPES]

const MAXIMUM_PRODUCTS = ['modules.account.planUpgrade.maximumNumberOfProducts', '3', '5', '10', 'modules.account.planUpgrade.unlimited']
const TRANSACTION_FEE = ['modules.account.planUpgrade.transactionFee', '3%', '3%', '3%', 'modules.account.planUpgrade.none']
const DIGITAL_PRODUCTS = ['modules.account.planUpgrade.digitalProducts', true, true, true, true]
const ONLINE_PAYMENTS = ['modules.account.planUpgrade.onlinePayments', true, true, true, true]
const ORDER_INVENTORY_TAX = ['modules.account.planUpgrade.orderAndTaxManagement', true, true, true, true]
const COUPONS_DISCOUNTS = ['modules.account.planUpgrade.discountAndCoupons', true, true, true, true]
const SHIPPING_MANAGEMENT = ['modules.account.planUpgrade.shippingManagement', 'modules.account.planUpgrade.basic', 'modules.account.planUpgrade.basic', 'modules.account.planUpgrade.basic', 'modules.account.planUpgrade.advancedIntegrated']

const ONLINE_STORE_ROW = [MAXIMUM_PRODUCTS, TRANSACTION_FEE, DIGITAL_PRODUCTS, ONLINE_PAYMENTS, ORDER_INVENTORY_TAX, COUPONS_DISCOUNTS, SHIPPING_MANAGEMENT]

const ONLINE_STORE = {
  header: ONLINE_STORE_HEADER,
  rows: ONLINE_STORE_ROW,
  modals: [],
  id: 'ONLINE_STORE'
}

/* SUPPORT */
const SUPPORT_HEADER = ['modules.account.planUpgrade.support', ...PLAN_TYPES]

const CHAT_EMAIL = ['modules.account.planUpgrade.chatEmail', true, true, true, true]
const PHONE_SUPPORT = ['modules.account.planUpgrade.phoneSupport', 'modules.account.planUpgrade.no', true, true, true]
const PRIORITY_SUPPORT = ['modules.account.planUpgrade.prioritySupport', 'modules.account.planUpgrade.no', 'modules.account.planUpgrade.no', true, true]

const SUPPORT_ROW = [CHAT_EMAIL, PHONE_SUPPORT, PRIORITY_SUPPORT]

const SUPPORT = {
  header: SUPPORT_HEADER,
  rows: SUPPORT_ROW,
  modals: [],
  id: 'SUPPORT'
}

export const FEATURE_COMPARISION_ALL = [WEBSITE_ESSENTIALS, EMAIL_MARKETING, MARKETING_TOOLS, ONLINE_STORE, SUPPORT]
