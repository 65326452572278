import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Pricing from '../components/pricing'
import DiscountBanner from '../components/discount-banner'
import DiscountTerms from '../components/discount-terms'

class PricingPage extends React.Component {
  render () {
    return (
      <Layout>
        <SEO
          title='Pricing'
          keywords={[`web hosting`, `domain names`, `web site`, `search engine optimization`, `hosting`, `servers`]}
          pageType={'product_page'}
          flowType={'none'} />
        <DiscountBanner />
        <Pricing />
        <DiscountTerms />
      </Layout>
    )
  }
}

export default PricingPage
